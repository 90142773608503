.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark{
  /* background-image: linear-gradient(
    195deg,#42424a,#191919); */
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  height: 700px;
  background: #fff;
}
section.ant-layout.ant-layout-has-sider .ant-layout-sider {
  position: fixed;
  min-width: 0;
  transition: all 0.2s;
  height: 100%;
  overflow: auto!important;
  height: 1000px;



}

section.ant-layout.ant-layout-has-sider {
    height: 100%!important;
    max-height: 1000px;
    min-height: 100vh;



}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent!important;
  background-image: none;
  box-shadow: none;
  border-radius: 4px;
  color: #2e8f81;


}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected span,
.ant-layout-sider-children .ant-menu-item-selected  span.ant-menu-title-content svg{
  color: #2e8f81;
  opacity: 1;
  transition: all 500ms ease-out;


}
span.ant-menu-title-content {
  display: flex;
  align-items: center;
}
.ant-layout-sider-children  ul li span {
  color: #66615B;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.8;
  text-transform: uppercase;




}
.ant-layout-sider-children span.ant-menu-title-content svg {
  font-size: 20px;
  color: #66615B;
  width: 22px;


}
.ant-layout-header{
  box-shadow:none!important;
  background: #f0f2f5!important;
}

.logo {
  text-align: center;
}
span.line_dash {
  margin-left: 7px;
}
.ant-layout-sider-children {
  padding-top: 22px;
}
section.ant-layout.new_layout {
  /* margin-left: 251px; */
  max-width: calc(100% - 254px);
    margin-left: auto;

}
.ant-layout-sider-children {
  padding-top: 15px!important;
}
.card {
  border-radius: 6px;
  box-shadow: 0 2px 2px rgb(204 197 185 / 50%);
  background-color: #FFFFFF;

}
.icon-lg {
  width: 64px;
  height: 64px;
  background-image: linear-gradient(
195deg,#42424a,#191919);
box-shadow: 0 4px 20px 0 rgba(0,0,0,.14),0 7px 10px -5px rgba(64,64,64,.4)!important;
border-radius: 5px;
text-align: center;
line-height: 60px;
font-size: 25px;
color: #fff;
position: absolute;
top: 0;
top: 22px;
left: 17px;
border-radius: 100%;
}
.notification_t h5 {
  font-size: 12px;
  font-weight: 600;
  width: 285px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.text-center.cost {
  text-align: center;
}
p.text-sm.mb-0.text-capitalize {
  margin-bottom: -3px;
}
.card {
  padding: 16px 16px 4px;
}
.card{
  position: relative;
}
.font-weight-bolder {
  font-weight: 600;
  font-size: 15px;
}
.text-end {
  text-align: right!important;
}
.text-capitalize {
  text-transform: capitalize!important;
}
h4.mb-0.weight {
  font-weight: 500;
  font-size: 25px;
  color: #344767;

}
.site-layout-background .content-new {
  display: grid;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 18px;
      grid-row-gap: 29px;
}
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(1) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(4) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(7) .icon

{
  background-image: linear-gradient(
    195deg,#ec407a,#d81b60);
}
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(2) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(4) .icon,
.content-new .col-xl-3.col-sm-6.mb-xl-0.mb-4:nth-child(6) .icon{
  background-image: linear-gradient(
195deg,#66bb6a,#43a047);
}
.bg-gradient-info {
  background-image: linear-gradient(
195deg,#49a3f1,#1a73e8);
}
button.button-list.top_new {
  margin-top: 0;
}
.ant-layout-sider{

    background:#fff!important;
    }
    button.ant-drawer-close {
      display: none;
  }
  .ant-drawer.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    height: auto!important;
  }
  .ant-layout{
    background: unset!important;

  }
  .new_layout{
    background-color: #f0f2f5!important;
  }
  .notification.button {
    position: absolute;
    font-size: 14px;
    color: #000;
    right: 40px;
    top: 4px;
}
.d-flexnew_n {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
}
.notification.button a.ant-dropdown-trigger.ant-dropdown-link {
  color: #9A9A9A;
  font-size: 14px;
  font-weight: 600;

}

.anchor_view_more {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark li {
  padding-left: 20px!important;
}
button.ant-btn.ant-btn-primary span {
  color: #fff;
}
.ant-tabs-tab-btn:focus,
 .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:hover,
   .ant-tabs-tab-remove:active {
  color: #289d8c!important;
}
.ant-table-wrapper.vouchers_d td.ant-table-cell:last-child,
.ant-table-wrapper.vouchers_d th.ant-table-cell:last-child{
  text-align: unset;
  
}
.color-error {
  color: red;
}

.new_container{
  overflow: auto;
  height: 194px;
  width: 327px;
} 
.notification_t {
  padding: 9px 21px;
  background-color: #f9f9f9;
  margin-bottom: 3px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
