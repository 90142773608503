@media(max-width:1250px){
    .icon-lg {
        width: 49px;
        height: 49px;
        font-size: 18px;
        line-height: 49px;
        /* top: -32px; */
        


}
.card {
    margin-bottom: 10px;
}
p.text-sm.mb-0.text-capitalize {
    font-size: 12px;
}
.card-footer p.mb-0 {
    font-size: 12px;
}
}

@media(max-width:991px){
    section.ant-layout.new_layout {
        margin-left: 0px;
        max-width: 100%!important;

    }
    
    .ant-drawer.ant-drawer-right.ant-drawer-open  .sider_left {
            display: block!important;
        
    }
    /* .sider_left{
        display: none;
    } */
    .sider_left.new{
        display: none;
    }


    .iocns{
     display: block!important;
    }
    .ant-drawer.ant-drawer-right.ant-drawer-open {
        display: block!important;
    }
    body.ant-scrolling-effect {
        overflow: hidden!important;
    }
    .card {
        margin-bottom: 28px;
    }
    .notification.button {
        right: 26px;
        top: 22px;

    }
    .right_column{
        width: 100%;
    }
    .right_chat {
        max-width: 100%!important;
        display: unset;
    }
    .site-layout-background .content-new {
    
        grid-template-columns: auto auto;
    
}
}

@media(max-width:480px){
    .site-layout-background .content-new {
        display: grid;
        grid-template-columns: auto;
}
p.text-sm.mb-0.text-capitalize {
    font-size: 12px;
}
.line_true {
    margin: auto 18px;
    
}
.top_button_item {
    padding: 20px 25px 0px 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 17px;
}
.bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3.system-line {
    display: flex;
    flex-direction: column;
}
}