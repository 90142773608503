section.ant-layout.ant-layout-has-sider .ant-layout-sider {
    z-index: 99;
}

/* .bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3 {
    background: #2e8f81!important;
    padding: 20px 20px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(109, 218, 208, .4)!important;
    position: relative;
    top: -24px;
    width: 96%;
    margin: auto;
    border-radius: 6px;
    height: 65px;
} */

.content-e {
    background-color: #fff;
}

.bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3 {
    margin-bottom: 4px;
    padding: 12px 18px 0;


}

.bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3.system-line {
    padding: 12px 13px 0;

}

.ant-table-thead>tr>th {
    background: #fff !important;
}

.content-e {
    border-radius: 8px;
    /* padding: 25px 0 24px; */

}

.content-e.login_1 {
    padding: 25px 0 24px;


}

h6.text-white.text-capitalize.ps-3 {
    color: #252422;
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0;
    font-weight: 600;
    /* margin-top: 18px; */



}

.login_2 {
    margin-top: 18px;

}

.ant-table-wrapper tr td button {
    border: 0;
    margin: 3px;
    color: #fff !important;
    /* padding: 6px 16px; */
    cursor: pointer;
    border-style: solid;
    border-color: rgb(10, 127, 110);
    background-image: -moz-linear-gradient(-109deg, rgba(57, 182, 164, 0.99608) 0%, rgb(2, 105, 90) 100%);
    background-image: -webkit-linear-gradient(-109deg, rgba(57, 182, 164, 0.99608) 0%, rgb(2, 105, 90) 100%);
    border: 3px solid #0c8372;
    border-radius: 8px;
    padding: 4px 6px;
    width: 70px;
    cursor: pointer;


}

.ant-breadcrumb {
    border-bottom: 1px solid #000;
    background-color: #f4f3ef;
    border-bottom: 1px solid #DDDDDD;
}

button.button-list {
    border: 0;
    margin: 3px;
    color: #fff !important;
    padding: 6px 16px;
    cursor: pointer;
    border-style: solid;
    border-color: rgb(10, 127, 110);
    background-image: -moz-linear-gradient(-109deg, rgba(57, 182, 164, 0.99608) 0%, rgb(2, 105, 90) 100%);
    background-image: -webkit-linear-gradient(-109deg, rgba(57, 182, 164, 0.99608) 0%, rgb(2, 105, 90) 100%);
    border: 3px solid #0c8372;
    border-radius: 8px;
    border-radius: 8px;
}

.wrapper_line {
    padding: 0px 20px 12px;
}

.breakWord {
    word-wrap: break-word;
}

.wrapper_line p {

    line-height: 40px;
    /* border-bottom: 1px solid #ccc; */
}

element.style {
    margin: 24px 16px 0px;
}

main.ant-layout-content.chat_new_one {
    margin-top: 14px !important;
}

.new-time {
    border-bottom: 1px solid #ccc !important;
    border: 0;

}

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.new-time12:before {
    content: '';
    position: absolute;
    bottom: 0;
    background-color: red;
    width: 100%;
    height: 3px;
    transform: scaleX(0);
    transition: 0.5s;

}

.new-time12.time:before {
    transform: scaleX(1);
    transition: 0.5s;

}

.new-time {
    width: 100%;
    transform: 0.5s;
}

.new-time12 {
    position: relative;

}

.ant-table img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    object-fit: cover;
    border: 2px solid #2e8f81;
}

input.form-control,
.wrapper_line p {
    width: 100%;
    border: 1px solid #097565;
    width: 100%;
    padding: 0 10px;
    background-color: #fffcf5;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    transition: background-color 0.3s ease 0s;
    height: 40px;
    box-shadow: none;

}

.form-group {
    margin-bottom: 20px;
}

button.button-list {
    margin-top: 52px;
}

.form-group label {
    font-weight: 500;
}

.line_true {
    width: 400px;
    margin: auto;
    position: relative;
    z-index: 99;
}

.image-bg {
    height: auto;
    min-height: 100vh;
}

.page-header {
    background-image: url(../images/login.png);
    min-height: 100vh;
    max-height: 1000px;
    display: flex !important;
    padding: 0;
    margin: 0;
    border: 0;
    color: #fff;
    position: relative;
    align-items: center;
    background-size: cover;
    background-position: top center;
}

.page-header:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .5);
    content: "";
}

.forrgot {
    color: #2e8f81 !important;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    margin-top: 7px;

}

h6.line_element a {
    color: #fff;
    margin-right: 10px;
}

.iocns {
    text-align: right;
}

.ant-drawer-content-wrapper ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    height: calc(100vh - 300px);
    background: transparent;
}

.ant-drawer-body {
    padding: 0 !important;
    overflow: initial !important;
}

.ant-drawer-content-wrapper aside.ant-layout-sider.ant-layout-sider-dark {
    /* transition: all 0.2s; */
    height: 100%;
    background-image: linear-gradient(195deg, #42424a, #191919);
}

.iocns {
    margin-right: 28px;
    font-size: 18px;
    display: none;
}

.top_button_item {
    padding: 25px 25px 0px 23px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

textarea.text_line {
    width: 100%;
    height: 90px;
    border: 0;
    color: #6a6969;
    border-bottom: 1px solid #ccc;
    resize: none;
}

.outer-circle {
    width: 100px;
    height: 100px;
    background-color: #efefef;
    border-radius: 100%;
    text-align: center;
    margin: auto;
}

.upload-image {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
    cursor: pointer;
}

img.line_item_one {
    position: absolute;
    bottom: 0;
    right: 0;
}

.ant-table-content {
    overflow: auto;

}

input:-internal-autofill-selected {
    background-color: #fff !important;
}

th.ant-table-cell:last-child {
    text-align: center;
}

td.ant-table-cell:last-child {
    text-align: center;
    white-space: nowrap;
    /* padding-right: 0; */


}

input.search-bar {
    border: 1px solid #097565;
    max-width: 200px;
    width: 100%;
    padding: 0 19px;
    background-color: #fffcf5;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    transition: background-color 0.3s ease 0s;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
}



.system-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button.ant-drawer-close {
    left: 0;
    background-color: #5dc0b0;
    padding: 7px 5px 8px 8px;
    text-align: center;
    /* border: 1
px
 solid #5dc0b0; */
}

.iocns {
    cursor: pointer;
}

.ant-layout-header {
    height: 7px !important;

}

main.ant-layout-content {
    margin-top: 0 !important;
    background-color: #f4f3ef;

}

.ant-breadcrumb {
    padding: 17px 34px 19px 40px !important;
}

span.ant-breadcrumb-link {
    color: #2e8f81;
}

.ant-breadcrumb span {
    font-size: 14px;
}

.ant-breadcrumb a:hover {
    color: #2e8f81 !important;

}

.iocns {
    z-index: 99;
    position: relative;
    position: absolute;
    right: 0;

}

.ant-drawer.ant-drawer-right.ant-drawer-open {
    display: none;
}

body.ant-scrolling-effect {
    overflow: auto !important;
    width: calc(100% - 0px) !important;

}

.text-center.model1 button {
    border: 0;
    background-color: #2e8f81;
    border: 0;
    margin: 3px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    color: #fff !important;
    padding: 4px 6px;
    width: 60px;
}

.text-center.model1 p {
    color: #000;
    font-size: 17px;
}

.text-center.model1 {
    text-align: center;
}

.ant-modal-header {
    background-color: #2e8f81 !important;
}

/* .ant-modal-title{
    visibility: hidden;
} */

.ant-btn-primary {
    background-color: #2e8f81 !important;
    border-color: #2e8f81 !important;
}

.ant-modal-title {
    color: #fff !important;
}

.ant-modal-content {
    border-radius: 6px !important;
}

.ant-btn:hover,
.ant-btn:focus {

    border-color: #2e8f81 !important;
    color: #2e8f81 !important;
}

.new-button button.ant-btn.ant-btn-primary {
    color: #fff !important;
}

.ant-pagination-item-active a {
    color: #2e8f81 !important;

}

.ant-pagination-item-active {
    border-color: #2e8f81 !important;

}

.ant-table-pagination.ant-pagination {
    margin-right: 10px !important;
}

.ant-breadcrumb>span:last-child a {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s;
}

.table-img {
    border-radius: 45px;
    /* border: 3px solid #000; */

}

img.plus_icon {
    width: 30px !important;
    position: absolute;
    bottom: 0;
    height: unset !important;
    right: 5px;
    top: 50px;
    background: white;
    cursor: pointer;
    border-radius: 30px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #2e8f81 !important;
}

section.ant-layout.new_layout {
    height: 100%;
    background-color: #f4f3ef !important;

}

body {
    background-color: #f4f3ef !important;

}

.logo {

    margin: 3px 25px 13px;
    border-bottom: 1px solid rgba(102, 97, 91, 0.3);
    padding-bottom: 20px;


}

.wrapper_line label {
    font-weight: 500;
}

.button_left {
    display: inline-block;
}

.ant-space.ant-space-vertical {
    float: right;
}

.menu_top button.button-list {
    margin-top: 0;
    margin-bottom: 22px;
    margin-right: 0px;
}

.menu_top {
    margin-left: 37px;
}

textarea.form-control.data {
    height: 209px;
    width: 100%;
    resize: none;
    padding: 8px 11px;
    background-color: #fffcf5;
    border-radius: 4px;
    color: #66615b;
    border: 1px solid #097565;


}

.ant-tabs.ant-tabs-top .text-center.button {
    margin-bottom: 4px;
}

.right_chat {
    background-color: #fff;
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
    min-width: 200px;
    max-width: 200px;
    height: calc(100vh - 200px);
    display: inline-block;
    overflow: hidden;

}

.right_column {
    /* float: right;
    display: inline-block;
    width: calc(100% - 220px); */

    /* float: right; */
    display: inline-block;
    /* width: calc(100% - 220px); */
    width: 100%;
}

.message-content {
    padding: 1rem 2.25rem;
    background-color: #f5f6fa;
    color: #8094ae;
    margin-left: 1.25rem;
    border-radius: 1.25rem;
    text-align: left;
    display: inline-block;
    max-width: 25rem;
    border: 3px solid #0c8372;
    word-break: break-all;
    white-space: pre-wrap;

}

.imge_wrappe.active span {
    color: #fff !important;
    opacity: 1;
}

.active_user .message-content h6.text-dark {

    font-size: 17px;
    color: #fff;


}

.message-content h6 {
    font-size: 17px;

}

.message-options {
    text-align: right;
}

.avatar.avatar-sm img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 0 0.5rem #fff;
    margin-top: 8px;

}

.active_user .message-content {
    color: #fff;
    background-color: #383838;
    border: 3px solid #0c8372;
    word-break: break-all !important;
    white-space: pre-wrap;
}

.active_user.wrapper_cli {
    text-align: right;
    padding-right: 20px;

}

.message-options {
    text-align: left;
}

.active_user .message-options {
    text-align: right;
}

.footer {
    background-color: #fff;
    padding: 19px 29px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: yellowgreen; */
    width: 100%;
}

.footer2 {
    background-color: #fff;
    padding: 19px 29px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: yellowgreen; */
    width: 100%;
}

button.button-list.data_R {
    margin-top: 0px;
    margin-bottom: 13px;
}

.home-chat {
    /* min-height: calc(100vh - 200px); */
    height: calc(100vh - 258px);
    overflow: auto;
    background-color: #fff;
    padding: 18px 9px;
    display: grid;
    align-items: flex-end;


}

.right_chat ul {
    padding-left: 0;
}

.right_chat ul li {
    padding: 8px 25px;

}

.right_chat ul li img {
    width: 30px;
    height: 30px;
}

.user-status {
    width: 10px;
    height: 10px;
    background-color: #adb5bd;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    background: red;
}

.item_t {
    position: relative;
    display: inline-block;
}

.imge_wrappe span {
    font-size: 14px;
    color: #5a636c;
    transition: all .4s;
    font-size: 14px;
    margin-left: 9px;

}

.imge_wrappe.active {
    border-color: rgb(10, 127, 110);
    background-image: -webkit-linear-gradient(-109deg, rgba(57, 182, 164, 0.99608) 0%, rgb(2, 105, 90) 100%);
    /* border: 3px solid #0c8372; */
}

.right_chat ul li {
    margin-bottom: 12px;
    padding: 0px 0;
}

.imge_wrappe {
    padding: 8px 9px;
}

.chat_point textarea {
    height: 72px;
    resize: none;
    width: 87%;
    border: 3px solid #0c8372;
    padding: 12px 11px;


}

.text-details.header {
    height: 65px;
    width: 100%;
    background-color: #383838;
}

.text-details.header .item_t img {
    width: 50px;
    height: 50px;
    margin-top: 7px;
    margin-left: 7px;
    border-radius: 50%;
}

.lite_ew {
    display: inline-block;
    vertical-align: middle;
    margin-left: 11px;
}

.lite_ew h4 {
    font-size: 14px;
    margin-bottom: 0;
    color: #fff;
}

.lite_ew p {
    margin-bottom: 0;
    font-size: 12px;
    color: #fff;
}

.imge_wrappe.active span {
    color: #fff;
}

.chat_point {
    display: initial;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer button.button-list.top_new {
    float: right;
    vertical-align: super;
}

button.button-list.active {
    background-color: #000;
}

.menu_top {
    margin-top: 20px;
}

.top_button_item.result {
    padding: 0px 23px 0;
}

.text_new_one {
    margin-bottom: 20px;
}

.ant-tabs-nav {
    padding: 9px 25px;
}

.ant-tabs-nav {
    margin-bottom: 0 !important;
    padding-bottom: 3px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2ea694 !important;
    text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-ink-bar {
    background: #2ea694 !important;

}

svg.svg-inline--fa.fa-arrow-left.fa-w-14 {
    color: #000;
    font-size: 18px;
}

.forrgot a {
    color: #097565 !important;
    text-decoration: underline;

}

.ant-picker-focused,
.ant-picker:hover {
    border-color: #097565 !important;
}

.ant-picker-range .ant-picker-active-bar {
    background: #097565 !important;

}

.ant-picker {
    border: 1px solid #097565 !important;

}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #269b8a !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #96dbd1 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {

    border: 1px solid #269b8a !important;

}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {

    background: #4fa598 !important;
}

.text-center.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

h6.text-white.line_element.text-capitalize.ps-3.arrows {
    position: absolute;
    top: 0;
    top: 15px;

}

span.ant-table-column-title {
    font-weight: 600;
}

.ant-table-thead>tr>th {
    font-weight: 600 !important;
}

th.ant-table-cell:last-child {
    text-align: center;
    font-weight: 600;
}

.ant-breadcrumb span {
    font-weight: 600;

}

/* .ant-tabs.ant-tabs-top h6.text-white.text-capitalize.ps-3 {
    padding-left: 6px;
} */
.bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3.system-line.line2 h6 {
    padding-left: 0 !important;
}

.bg-gradient-primary.shadow-primary.border-radius-lg.pt-4.pb-3.system-line.change {
    padding-left: 19px;
}

input.search-bar {
    background-image: url(../images/search1.png);
    background-position: 6% 45%;
    background-repeat: no-repeat;
    padding-left: 42px;
    background-size: 21px;
}

ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    pointer-events: none;
}

.item_t span.user-status.green {
    background-color: green;
}

p.text-sm.mb-0 {
    font-weight: 600;
}

span.ant-picker-suffix {
    cursor: pointer;
    position: relative;
}

.ant-picker.ant-picker-range {
    cursor: pointer;
}

.ant-picker-input>input {
    cursor: pointer;
}

.wrapper_cli .message-options {
    padding-left: 20px;
    padding-bottom: 20px;
}

.txtb {
    margin-top: 28px;
}

.ant-pagination {
    text-align: end;
    padding: 10px 0 !important;
}

.label_space .txtb {
    margin-top: 6px;
}

span.special_breadcrumb {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
}

span.special_breadcrumb:hover {
    color: #2e8f81 !important;
}

.dash_icon {
    height: 1.4rem;
    margin-bottom: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.value_paragraph {
    word-break: break-all;
}

.ant-table-tbody>tr>td {
    word-break: break-all;
}

.wrapper_line p {
    padding: 10px;
    word-break: break-all;
    height: auto;
    line-height: normal;
}

.text-message {
    /* -webkit-user-modify: read-write-plaintext-only; */
    word-break: break-all;
}

.no-data {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spin-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.spin-loader .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #0c8372;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    -webkit-animation: antSpinMove 1s infinite linear alternate;
    animation: antSpinMove 1s infinite linear alternate;
}

button.button_new_one.popupbuttonone {
    position: unset;
    width: 236px;
    /* width: 201px; */

}

.text-center.listings.center-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.borderone {
    border: 1px solid #0c8372 !important;
    background-color: #fff !important;
    color: #0c8372 !important;
    font-weight: 700 !important;

}

.button_new_one {
    color: #0c8372;
    font-size: 17px;
    width: 123px;
    border-radius: 30px;
}

.button_new_one {
    border: 0;
    background-color: #0c8372;
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    height: 47px;
    letter-spacing: 0.3px;
    line-height: 47px
}

.error-msg {
    color: red;
    padding-left: 30px;
}

.main-footer {
    background-color: #fff;
    width: 100%;
}

.downloadSampleWrapper {
    position: absolute;
    top: 26px;
    right: 22px;
}