.containerStyle {
  margin-top: 9;
}

.inputStyle {
  min-height: 30;
  font-size: 20;
}

.errorInput {
  color: red;
  font-size: 15;
}

.errorInput-categoryForm {
  color: red;
  font-size: 15;
  max-width: fit-content;
}
.ant-input:focus,
.ant-input-focused {
  border-color: lightgray !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}
.ant-input:hover {
  border-color: lightgray !important;
  border-right-width: 1px !important;
}
.ant-input {
  padding: 0px !important;
}
